import React, { useState } from 'react';
import { Accordion } from '@naf/accordion';
import { Text, TextVariant } from '@naf/text';
import { Checkbox } from '@naf/checkbox';
import { GuideFilterGroup } from '../../../../../../types/consumerTestType';
import * as S from './Styles';

export interface FilterComponentProps {
  filters?: GuideFilterGroup[];
  selectedFilters?: Record<string, string[]>;
  onFilterChange?: (filterGroup: string, filterValue: string) => void;
}

const TestFilter = ({ filters, selectedFilters, onFilterChange }: FilterComponentProps) => {
  const [showExtraMobileFilters, setShowExtraMobileFilters] = useState(false);
  const handleCheckboxChange = (filterGroup: string, filterValue: string) => {
    onFilterChange(filterGroup, filterValue);
  };

  const showMoreFilters = () => {
    setShowExtraMobileFilters(!showExtraMobileFilters);
  };

  if (!filters.length) {
    return <div>Laster filter...</div>;
  }

  return (
    <S.Wrapper>
      <S.DesktopWrapper>
        {filters.map((filterGroup) => (
          <S.ExpandableListWrapper key={filterGroup.title} direction="vertical">
            {filterGroup.filterValues
              .filter((filterValue) => filterValue.numberOfMatchingIds > 0)
              .map((filterValue, index) => (
                <React.Fragment key={filterValue.label}>
                  {index === 0 && filterGroup.title !== filterValue.label && (
                    <Text tag="h3" variant={TextVariant.ArticleTextHeader}>
                      {filterGroup.title}
                    </Text>
                  )}
                  {filterGroup.title === filterValue.label ? (
                    <S.CheckboxWrapper>
                      <Checkbox
                        onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                        name={filterValue.label}
                        label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                        checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                      />
                    </S.CheckboxWrapper>
                  ) : (
                    <Checkbox
                      onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                      name={filterValue.label}
                      label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                      checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                    />
                  )}
                </React.Fragment>
              ))}
          </S.ExpandableListWrapper>
        ))}
      </S.DesktopWrapper>
      <S.MobileWrapper>
        {filters.slice(0, 2).map((filterGroup) =>
          filterGroup.title === filterGroup.filterValues[0].label ? (
            filterGroup.filterValues
              .filter((filterValue) => filterValue.numberOfMatchingIds > 0)
              .map((filterValue) => (
                <S.CheckboxWrapper key={filterValue.label}>
                  <Checkbox
                    onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                    name={filterValue.label}
                    label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                    checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                  />
                </S.CheckboxWrapper>
              ))
          ) : (
            <Accordion key={filterGroup.title} label={filterGroup.title} size="medium">
              <S.AccordionContent>
                {filterGroup.filterValues
                  .filter((filterValue) => filterValue.numberOfMatchingIds > 0)
                  .map((filterValue) => (
                    <React.Fragment key={filterValue.label}>
                      {filterGroup.title === filterValue.label ? (
                        <S.CheckboxWrapper>
                          <Checkbox
                            onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                            name={filterValue.label}
                            label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                            checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                          />
                        </S.CheckboxWrapper>
                      ) : (
                        <Checkbox
                          onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                          name={filterValue.label}
                          label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                          checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                        />
                      )}
                    </React.Fragment>
                  ))}
              </S.AccordionContent>
            </Accordion>
          ),
        )}
        <S.ExtraFiltersWrapper $showMobileFilters={showExtraMobileFilters}>
          {filters.slice(2).map((filterGroup) =>
            filterGroup.title === filterGroup.filterValues[0].label ? (
              filterGroup.filterValues
                .filter((filterValue) => filterValue.numberOfMatchingIds > 0)
                .map((filterValue) => (
                  <S.CheckboxWrapper key={filterValue.label}>
                    <Checkbox
                      onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                      name={filterValue.label}
                      label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                      checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                    />
                  </S.CheckboxWrapper>
                ))
            ) : (
              <Accordion key={filterGroup.title} label={filterGroup.title} size="medium">
                <S.AccordionContent>
                  {filterGroup.filterValues
                    .filter((filterValue) => filterValue.numberOfMatchingIds > 0)
                    .map((filterValue) => (
                      <React.Fragment key={filterValue.label}>
                        {filterGroup.title === filterValue.label ? (
                          <S.CheckboxWrapper>
                            <Checkbox
                              onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                              name={filterValue.label}
                              label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                              checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                            />
                          </S.CheckboxWrapper>
                        ) : (
                          <Checkbox
                            onChange={() => handleCheckboxChange(filterGroup.title, filterValue.label)}
                            name={filterValue.label}
                            label={`${filterValue.label} (${filterValue.numberOfMatchingIds})`}
                            checked={selectedFilters[filterGroup.title]?.includes(filterValue.label) || false}
                          />
                        )}
                      </React.Fragment>
                    ))}
                </S.AccordionContent>
              </Accordion>
            ),
          )}
        </S.ExtraFiltersWrapper>
        {filters.length > 2 && (
          <S.ShowMoreFiltersWrapper onClick={showMoreFilters}>
            {!showExtraMobileFilters ? (
              <>
                &#43; <span>Vis flere filtre</span>
              </>
            ) : (
              <>
                - <span>Lukk filter</span>
              </>
            )}
          </S.ShowMoreFiltersWrapper>
        )}
      </S.MobileWrapper>
    </S.Wrapper>
  );
};

export default TestFilter;
