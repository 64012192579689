import styled, { css } from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { Form } from '@naf/checkbox';
import { breakpoints, spacing } from '@naf/theme';

export const DesktopWrapper = styled.div`
  display: block;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
    margin: 0 18px;
  }
`;

export const ExpandableListWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 25px;

  h3:first-child {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-left: ${spacing.space24};
    margin-right: ${spacing.space24};
  }
`;

export const Wrapper = styled.div`
  p::first-letter {
    text-transform: uppercase;
  }
`;

export const CheckboxWrapper = styled.div`
  padding-top: ${spacing.space24};

  @media (max-width: ${breakpoints.m}) {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: ${spacing.space24};
  }
`;

export const AccordionContent = styled(Form)`
  display: flex;
  flex-direction: column;
  margin: 0;
`;

export const ShowMoreWrapper = styled.button`
  border: none;
  text-align: left;
  background: none;
  font-size: 1rem;
  padding: 0;
  margin-top: 17px;
  color: ${nafColor.signature.black} !important;

  &:hover,
  &:focus {
    color: ${nafColor.primary.park};
  }

  &:active,
  &:visited {
    color: ${nafColor.signature.black} !important;
  }

  span {
    text-decoration: underline;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const ShowMoreFiltersWrapper = styled(ShowMoreWrapper)`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: block;
    margin-right: ${spacing.space12};
  }
`;

export const ExtraFiltersWrapper = styled.div<{ $showMobileFilters?: boolean }>`
  display: block;

  @media (max-width: ${breakpoints.m}) {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
    margin: 0;

    form {
      margin-left: 0;
      margin-right: 0;
    }

    ${({ $showMobileFilters }) =>
      $showMobileFilters &&
      css`
        max-height: 10000px;
        transition: max-height 1s ease-in;
      `}
  }
`;
